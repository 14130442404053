body {
  margin: 0;
  font-family: "CallingCode", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #f4fbfd;
}

@font-face {
  font-family: "CallingCode";
  src: local("CallingCode"),
    url("./assets/fonts/calling_code/CallingCode-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Mono";
  src: local("Mono"),
    url("./assets/fonts/mono/Mono\ Regular.ttf") format("truetype");
}