#content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    padding: 1em;
}

#company {
    font-size: 3em;
    font-family: "Mono";

    text-align: center;

    /* Force the space between company and logo / outside to be constant */
    margin-top: 0;
}