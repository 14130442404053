#footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 1em;
}

#contact {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#social-media {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
}

#location {
    text-align: center;
}

@media (min-width: 600px) {
    #footer {
        padding: 2em;

        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }


    #social-media {
        gap: 1em;
    }

    #contact {
        width: auto;
        flex-direction: column;
    }
}


#copyright--company {
    font-family: "Mono";
    padding-bottom: 0.5em;
    border-bottom: 0.2em #ff9119 solid;
}

#copyright--content,
#location {
    margin: 0;
}