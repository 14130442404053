#header {
    padding: 1em;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 600px) {
    #header {
        padding: 2em;
    }
}