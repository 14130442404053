#section {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.section--comment-line {
    margin: 0;
}

.section--binary-code {
    color: #ff9119;
    margin-right: 1em;
    font-weight: bold;
}