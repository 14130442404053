#language--selector {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

#language--selector div p {
    margin: 0;
}

.language--item--container--notselected p {
    opacity: 0.5;
}

.language--item--container--notselected:hover {
    cursor: pointer;
}

.language--item--container--notselected:hover p {
    opacity: 1;
}

.language--item--container--selected {
    border-bottom: 0.2em #ff9119 solid;
}